import React from 'react';

import { Intro } from 'components/intro/Intro';
import SEO from 'components/seo';

// tslint:disable no-default-export
export default () => (
  <>
    <SEO title="About" />

    <Intro>About page</Intro>
  </>
);
